<template>
  <v-row no-gutters>
    <v-col cols="8">
      <!-- writer  -->
      <WriterComponent :detail="bid.writer" />
      <!-- Writer  -->
      <ChatStarter :room="bid.room" />
    </v-col>
    <v-col cols="1">
      <v-divider vertical class="ml-5 mt-0"></v-divider>
    </v-col>

    <v-col class="ml-n7 mr-n10">
      <div class="">
        <span class="text-subtitle-1 text-muted"> Writer's bid: </span>
         <v-alert
          :type="actionClass"
          class="mr-15"
          v-if="message"
          dismissible
        >
          {{ message }}
        </v-alert>
        <div class="text-h4 text-muted font-weight-black">
          ${{ bidAmount }}
        </div>
        <div class="mt-3" v-if="bid.bid_status">
          <v-badge
            inline
            icon="mdi-marker-check"
            color="green"
            left
            tile
            class="mt-7"
            v-if="bid.bid_status === 'short_listed'"
          >
            <span class="ml-2">Shortlisted bid </span>
          </v-badge>

          <v-badge
            inline
            icon="mdi-close-circle-outline"
            color="red"
            left
            tile
            v-if="bid.bid_status == 'rejected'"
            class="mt-7"
          >
            <span class="ml-2">Bid was automatically cancelled</span>
          </v-badge>
        </div>
        <div class="mt-5" v-if="bid.bid_status !== 'rejected'">
          <v-btn small color="success" class="mr-3" @click="showHire=true" :loading="loading_1">
            Hire Writer
          </v-btn>
          <!-- <v-btn small text outlined   @click="shortList(bid.id)" :loading="loading_2" >
            Add to shortlist
          </v-btn> -->
        </div>
      </div>
    </v-col>
    <reviewModal :show="show" @close="show = false" />
    <HireModal :bid="bid" :show="showHire" @close="showHire= false" />
  </v-row>
</template>

<script>
import {mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import generalMxn from "@/mixins/general_mixin";
import connection from '@/socket/utils';

export default {
  name: "bidComponent",
  components: {
    ChatStarter: () => import("@/modules/writersDashboard/orderDetail/_components/chatStarter"),
    reviewModal: () => import("@/modules/Writers/components/reviewModal"),
    WriterComponent: () => import("@/modules/MyOrders/MyBids/components/writerComponent"),
    HireModal: () => import("@/modules/MyOrders/MyBids/components/HireModal")
  },
  props: ["bid"],
  mixins: [colorMxn, validationMxn, generalMxn],
  data() {
    return {
      show: false,
      showHire: false,
      details: [
        {
          name: "Chris Robert",
          orders: "30033",
          rating: 4.8,
          reviews: 225
        },
        {
          name: "Chris Robert",
          orders: "30033",
          rating: 4.8,
          reviews: 225
        },
        {
          name: "Chris Robert",
          orders: "30033",
          rating: 4.8,
          reviews: 225
        }
      ],
      loading_1: false,
      loading_2: false,
      actionClass: '',
      message: '',
      bidAmount:'',
      chatMessage: this.bid.room.last_message,
      messages: this.bid.room.messages,
      chatSocket: null,
    };
  },
  mounted() {
    this.bidAmount = this.bid.amount;
    connection.connect();
    this.chatSocket = connection.subscribe(`chat:bid-${this.bid.id}`, this.handleMessageAdd);
  },
  methods: {
    ...mapMutations(['setChatStatus']),
    chatInit() {
      const data = {
        status: true,
        room: this.bid.room
      };
      this.setChatStatus(data);
    },
    async hireWriter(id) {
      this.loading_1 = true;
      const payload = {
        bid_status: 'hired',
      };
      const fullPayload = {
        params: payload,
        endpoint: `/bid/update-bid-status/${id}`,
      };
      const response = await this.performUpdateActions(fullPayload);
      this.loading_1 = false;
      this.actionClass = response.data.status === 'success' ? 'success': 'error';
      this.message = response.data.message;
      if (response.data.status === 'success') {
        this.$router.push(`/dashboard/order/${this.$route.params.id}`)
      }
    },
    async shortList(id) {
      this.loading_2 = true;
      const payload = {
        bid_status: 'short_listed',
      };
      const fullPayload = {
        params: payload,
        endpoint: `/bid/update-bid-status/${id}`,
      };
      await this.performUpdateActions(fullPayload);
      this.loading_2 = true;
      location.reload();
    },
    handleMessageAdd(data) {
      this.bidAmount = data.amount;
    }
  }
};
</script>
